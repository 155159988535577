body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

::selection {
  background: rgba(0, 0, 0, 0.1) !important;
  color: inherit !important;
}

input:autofill {
  background: #fff; /* or any other */
}
